<template>
  <div>
    <ImageOpprotunity />

    <!-- main content-detail -->
    <div class="site-container" v-if="item !== null">

      <div class="content-detail">

        <div class="image">
          <div v-if="item.photo !== null" class="photo-detail" :style="{'background-image': 'url(data:image/jpeg;base64,'+item.photo+')'}">
          <div v-if="item.photo === null" class="photo-detail" :style="{'background-image': 'url(data:image/jpeg;base64,'+noImage+')'}" />
          </div>
          <div class="left size_total margin-bottom-image">
            <div class="left item">Status: <span>{{ item.quotasAcquiredPercent }}% Captado</span></div>
            <div class="right item">Meta Mínima: <span>{{ item.goal.toFixed(2) }} Cotas</span></div>
            <div style="clear: both" />
            <div class="left size_total">
              <b-progress :value="item.quotasAcquiredPercent" :max="100" animated />
            </div>
          </div>
        </div>

        <div class="detail left margin-left">
          <div class="header">
            <div class="back action" @click="back()">
              <b-icon-arrow-left-short class="icon" />
              Voltar para Lista
            </div>
            <div class="name">{{ item.name }}</div>
              <div class="share action" href="#" @click="share()">
                <b-icon-share-fill id="link-button2" class="icon" /> Compartilhar oferta
              </div>
          </div>

      <div class="body">

        <div class="">
          <div class="column">

            <div class="column-info-1">
              <div class="item">Construtora :<br><span>{{ item.companyName }}</span></div>
              <div class="item">Rentabilidade Estimada:<br><span>{{ item.estimatedProfitability }}</span></div>
              <div class="item">Cidade:<br><span>{{ item.city }}/{{ item.state }}</span></div>
              <div class="item">Previsão de Entrega:<br><span>{{ formatDate(item.realeseDate) }}</span></div>
            </div>

            <div class="column-info-2">
              <div class="item">Metragem:<br><span>{{ item.privateAreaSize }}㎡</span></div>
              <div class="item">Investidores:<br><span>{{ item.qtdInvestors }}</span></div>
              <div class="item">Tipo de Imóvel:<br><span>{{ item.typeImmobile }}</span></div>
              <div class="item valor">Valor da Cota:<br><span>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.quotaValue) }}</span></div>
            </div>
          </div>

          <div style="padding:0px;">
            <div class="column-info-button">

              <div v-if="item.quotaAvailable" class="">
              <div class="">
                <b-button class="btn-block invest" @click="investNow(item.idImmobile)">Investir Agora</b-button>
              </div>

            </div>

            <div class="column-info-button-2">

              <div>
                  <a :href="talkSpecialist()" target="_blank">
                  <b-button class="btn-block talk">Falar com Especialista</b-button>
                </a>
              </div>

            </div>
          </div>

            </div>
          </div>

            <div class="item" v-if="!item.quotaAvailable">
              <div class="">
                <b-button v-b-modal.modaQeueWait class="talk wait-queue">Fila de Espera</b-button>
              </div>

                <b-modal id="modaQeueWait" title="Fila de Espera">
                  <div class="modal-queue">
                    <h1>Atenção</h1>
                    <div class="modal-queue-content">
                      Ao entrar na fila de espera, você será notificado por email caso haja desistência de uma ou mais cotas para este imóvel. Dessa forma será possível comprar cotas deste imóvel.
                    </div>
                    <div class="modal-queue-footer">
                        <b-form-checkbox>Deseja entrar da fila de espera?</b-form-checkbox>
                    </div>
                  </div>

                </b-modal>
            </div>
          </div>

        </div>

      </div>

      <div style="clear: both" />

      <div class="site-item">
        <MoreInfo name="moreinfo" :item="item" />
      </div>

      <div class="site-item">
        <Similar />
      </div>

      <div class="site-item">
        <newsletter-card />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>

import { BButton, BProgress, BModal, BFormCheckbox } from 'bootstrap-vue'
import Footer from '../components/parts/footer/Footer.vue'
import ImageOpprotunity from '../components/images/ImageOpportunity.vue'
import MoreInfo from '../components/opportunity/MoreInfo.vue'
import Similar from '../components/opportunity/Similar.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import OpportunityService from '../services/OpportunityService'
import TalkSpecialistConfigService from '@/services/Investment/TalkSpecialistConfigService'

export default {
  name: 'SiteOportunityDetail',

  components: {
    Footer,
    BButton,
    ImageOpprotunity,
    BProgress,
    MoreInfo,
    Similar,
    NewsletterCard,
    BModal,
    BFormCheckbox,
  },

  async mounted() {
    await this.getItem()
    await this.getTaklSpecialistData()
  },

  computed: {
    urlTalk(){
      
    }
  },

  data() {
    return {
      url: window.location.href,
      item: null,
      talkSpecialistData: {},
    }
  },

  methods: {
    back() {
      this.$router.push({ path: '/opportunity' })
    },
    share() {
      let url = this.url
      if(localStorage.getItem('role') === 'BROKER') {
        url = url + '/'+localStorage.getItem('id')
      }
      navigator.clipboard.writeText(url)
      this.notifyCopy()
    },
    notifyCopy() {
      this.$bvToast.toast('Link copiado.', {
        autoHideDelay: 2000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
      })
    },

    async getTaklSpecialistData(){
      this.talkSpecialistData = (await TalkSpecialistConfigService.GetTaskSpecialistConfigForEdit()).data.result.taskSpecialistConfig
    },
    
    talkSpecialist(){
      this.talkSpecialistData.phone = this.talkSpecialistData.phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
      let msg = this.talkSpecialistData.msg
      while(msg.includes('\n')){
        msg = msg.replace('\n', '%0A')
      }
      if (this.talkSpecialistData.isAddUrl === true) {
        let url = this.url
        msg =`${msg}%0A ${url}`
      }
      return this.talkSpecialistData.phone !== null ? `https://wa.me/55${this.talkSpecialistData.phone}?text=${msg}` : ''
    },
    
    async getItem() {
      const a = await OpportunityService.getDetails(this.$route.params['id'])
      if(this.$route.params['brokerUserId'] !== undefined) {
        localStorage.setItem('brokerUserId', this.$route.params['brokerUserId'])
      }
      this.item = a.data.result
    },

    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('pt-BR').format(date)
    },
    investNow(id) {
      localStorage.setItem('immobileIdSelected', id)
      this.$router.push({ path: '/checkout' })    },
  },
}
</script>

<style scoped>

.modal-queue {
}

.modal-queue h1 {
  text-align: center;
}
.modal-queue .modal-queue-footer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.valor span {
  font-size: 2.0em;
  font-weight: 500 !important;
  color: orange;
}

.share {
  background-color: transparent !important;
  width: 100%;
  text-align: left;
  border:none;
  padding:0;
}
.action:hover,
.share:hover {
  color: orange !important;
  box-shadow: 0;
}

.action {
  cursor: pointer;
  transition: .3s;
}

.icon {
  margin: 0px 5px 0px 0px;
}

.site-container {
  margin-top: -270px;
}

.size_total {
  width: 100%;
}
.margin-left {
  margin-left: 30px;
}
.col2 {
  margin-left: 50px;
}
.left {
  float: left;
}
.right {
  float: right;
}
.text-right {
  text-align: right;
}

.content-detail {
  align-items: center;
}

.content-detail .image {
  float:left;

}
.content-detail .image .photo-detail {
  width: 556px;
  height: 588px;
  border-radius: 12px;
  z-index: 1;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-detail .detail .header {
  margin-top: 20px;
  position: relative;
}
.content-detail .detail .header .name {
  font-size: 2.0rem;
  font-weight: 600;
}
.content-detail .detail .header .back {
  font-size: 0.9rem;
}
.content-detail .detail .header .share {
  font-size: 0.9rem;
  position: absolute;
  bottom: 0px;
}

.content-detail .item {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.content-detail .detail .item {
  margin: 20px 0px 20px 0px;
}
.content-detail .invest {
  white-space: nowrap;
  background-color: orange !important;
  border: none;
  padding: 15px 40px 15px 40px;
}
.content-detail .talk {
  white-space: nowrap;
  font-weight: 450;
  border: none;
  background-color: white !important;
  color: black !important;
  padding: 15px 40px 15px 40px;
}
.content-detail .wait-queue {
  width: 100%;
  border: 1px solid #E9E9E9  !important;
}
.content-detail .detail .body {
  width: 100%;
  display: inline-block;
}
.content-detail .item span {
  font-weight: 100;
}
.margin-bottom-image {
  margin-top: 10px;
}

@media screen and (min-width: 1206px) {

  .content-detail .detail .header {
    color: white;
    height: 200px;
    margin-bottom: 20px;
  }
  .content-detail .detail .header .name {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .content-detail .detail .header .back {
    position: absolute;
    top: 0px;
  }

  .site-item {
    margin: 50px 0px 50px 0px ;
  }
  .content-detail .detail .body {
    margin-top: 30px;
  }

  .column-info-button-2{
   display:flex;
   width:49%;
   padding-left: 5px;
  }

}

.column {
  display: flex;
 }
  .column-info-1{
    width:50%;
    padding-right: 50px;
  }
  .column-info-2{
    width:50%;
  }
  .column-info-button{
    display:flex;
    width:49%;

  }

@media screen and (max-width: 1000px) {
  .column-info-1{
    width:100%;
  }
  .column-info-2{
    width:100%;
  }
  .column-info-button{
    display: block;
    width:100%;

  }
 .content-detail .detail .header {
    color: black;
    height: 100px;
  }
  .content-detail .detail .header .name {
    margin-top: 0px;
  }
  .content-detail .detail .body {
    margin-top: 0px;
  }

  .share {
    margin-top: 20px;
  }
}

@media screen and (min-width: 601) {
  h1 {
    font-size: 2.6rem;
    flex: 1 1 1;
  }
}

@media screen and (max-width: 500px) {

  h1 {
    font-size: 1.6rem;
  }

  .column-info-button-2{
    display: block;
    width:100%;
    padding-top: 5px;
  }

  .content-detail .image .photo-detail {
  width: 350px;
  height: 400px;
  border-radius: 12px;
  z-index: 1;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

}

h1 {
  margin-bottom: 40px;
  font-weight: 700;
  color: black;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

h1,
h2,
h3,
p {
  line-height: 1.3;
}

.scroll-hide p {
  scroll-snap-align: center;
  line-height: 1;
}
.scroll-hide {
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  margin-top: 60px;
}

.scroll-hide p {
  margin-bottom: 0;
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.por-tras-da-divvy {
  background-size: contain;
  background-repeat: repeat-x;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

</style>
