<template>
  <div>
    <div class="header-similar">
      <div class="title">{{ data.title }}</div>
      <div class="menu">
        <div class="button" v-bind:class="{ disabled: filter.skipCount === 0 }">
          <b-icon-arrow-left-short v-bind:class="{ disabled: filter.skipCount === 0 }" class="icon" @click="loadLess()" />
        </div>
        <div class="button" v-bind:class="{ disabled: !isMore }">
          <b-icon-arrow-right-short class="icon" v-bind:class="{ disabled: !isMore }" @click="loadMore()" />
        </div>
      </div>
    </div>

    <div style="clear: both" />

    <div class="body-similar">
        <CardOpportunityComponent v-for="item in data.opportunities" :key="item.idImmobile" :item="item" :solded="false" />
    </div>

  </div>
</template>

<script lang="ts">
import { } from 'bootstrap-vue'
import CardOpportunityComponent from './CardOpportunity.vue'
import OpportunityStore from '../../services/OpportunityService'

export default {

  name: 'OpportunitySimilarComponent',

  startDate: null,
  endDate: null,
  isCloseable: false,

  components: {
    CardOpportunityComponent,
  },

  props: {
    items: [],
  },

  data() {
    return {
      isLoading: true,
      isMore: true,
      filter: {
        skipCount: 0,
        maxResultCount: 3,
      },
      data: {
        title: 'Oportunidades Similares',
        opportunities: [],
      },
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async loadLess() {
      if (this.filter.skipCount > 0) {
        this.filter.skipCount -= 3
        await this.load()
      }
    },
    async loadMore() {
      this.filter.skipCount += 3
      await this.load()
    },
    async load() {
      this.isLoading = true
      const oppeneds = await OpportunityStore.getOpenedOpportunities(this.filter)
      this.isMore = oppeneds.data.result.length === 3
      if (oppeneds.data.result.length > 0) {
        this.data.opportunities = oppeneds.data.result
      }
      this.isLoading = false
    },
  },
}
</script>

<style>

    .header-similar {
        border-top: 1px solid #E9E9E9;
    }
    .header-similar .title {
        margin: 30px 0px 30px 0px;
        font-size: 2.0em;
        font-weight: 800;
        float: left;
    }
    .header-similar .menu {
        float: right;
        margin: 30px 0px 30px 0px;
    }
    .header-similar .menu .button{
        width: 50px;
        height: 50px;
        color: orange;
        box-shadow: 0 0 .8em rgba(0, 0, 0, .1);
        border-radius: 100%;
        float: left;
        cursor: pointer;
        transition: .3s;
    }
    .header-similar .menu .button:first-child  {
        margin-right: 40px;
    }
    .header-similar .menu .button .icon {
        width: 30px;
        height: 30px;
        margin: calc(50% - 15px);
    }
    .disabled {
      color:#E3E3E3 !important;
      cursor: normal;
    }
    .disabled:hover {
      background-color: white !important;
      cursor: unset !important;
    }
    .header-similar .menu .button:hover {
        background-color: #E9E9E9;
    }
    .body-similar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
</style>
