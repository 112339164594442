<template>
  <div class="card-compare-investment-chart">
    <div class="title"><h3>Gráfico de Rentabilidade Comparada</h3></div>
    <line-chart :chart-data="datacollection" :styles="myStyles" :options="options" chart-options: />
  </div>
</template>


<script lang="ts">
import { } from 'bootstrap-vue'
import LineChart from '@/components/charts/Line'
import ComparativeProfitabilitieService from '@/services/Investment/ComparativeProfitabilitieService'

export default {
  name: 'CardValorizationInvestmentComponent',
  components: {
    LineChart,
  },

  props: [
    'immobileId',
  ],
  mounted() {
    this.fillData()
    this.renderChart(this.chartData, this.options)
  },

  computed: {
    myStyles() {
      return {
        height: '380px',
      }
    },
    chartOptions() {
      return {

      }
    },
  },

  data() {
    return {
      datacollection: {},
      options: {
        title: {
          display: true,
        },
        pieceLabel: {
          mode: 'value',
          precision: 1,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
        },

      },
    }
  },
  methods: {
    async fillData() {
      const { result } = (await ComparativeProfitabilitieService.getHistory(this.immobileId)).data

      this.datacollection = {
        labels: result.months,
        datasets: [
          {
            label: 'Otimista',
            borderColor: '#666EE8',
            data: result.optimistic,
            fill: false,
          },
          {
            label: 'Realista',
            borderColor: '#FFE802',
            data: result.realistic,
            fill: false,
          },
          {
            label: 'Conservador',
            borderColor: '#FF4961',
            data: result.conservative,
            fill: false,
          },
        ],
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    back() {
      this.$emit('onBack')
    },
  },
}
</script>

<style>

    .card-compare-investment-chart {
      display: contents;
    }

    .card-compare-investment-chart .title {
      float: center;
      margin-bottom: 20px;
    }

    .card-compare-investment-chart .title h3 {
     font-weight: 800 !important;
    }

    .card-compare-investment-chart .back-action {
      float: right;
      font-size: 0.9rem;
      font-weight: 800;
      cursor: pointer;
    }

    .card-compare-investment-chart .back-action:hover {
      color: orange;
    }
</style>
